<script setup>
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useVueToPrint } from "vue-to-print";
import ShipmentService from "@/service/ShipmentService";
import ReceiptService from "@/service/ReceiptService";
import PackageService from "@/service/PackageService";
import ShippingForm from "./components/ShippingForm";
import ShippingFormEms from "./components/ShippingFormEmsXlsx";
import ShippingLabel from "./components/ShippingLabel";
const printComponentRef = ref();
const route = useRoute()
const types = ref([
    { name: "Shipping form", value: "shippingForm" },
    { name: "Shipping form ems", value: "shippingFormEms" },
    { name: "Shipping label", value: "shippingLabel" }
]); 
const selectedType = ref("shippingFormEms");
const receipts = ref({});
const packages = ref([]);
const loaded = ref("");
const pageCounter = ref(0);
const handleAfterPrint = async () => {
    const {resource, id, packageId} = route.params;
    if (id) {
        if (resource == "shipment") {
            await ShipmentService.storePrintLog(selectedType.value, id, packageId);
            // .then(() => {
            //     toast.add({severity:'success', summary: 'Successful', detail: "Store print history successfully", life: 3000});
            // });
        } else if (resource == "receipt") {
            await ReceiptService.storePrintLog(selectedType.value, id, packageId);
            // .then(() => {
            //     toast.add({severity:'success', summary: 'Successful', detail: "Store print history successfully", life: 3000});
            // });
        } else if (resource == 'package') {
            await PackageService.storePrintLog(selectedType.value, atob(id));
            // .then(() => {
            //     toast.add({severity:'success', summary: 'Successful', detail: "Store print history successfully", life: 3000});
            // });
        }
    }
}

const handleBeforePrint = () => {
  //console.log("`onBeforePrint` called"); // tslint:disable-line no-console
};

const handleOnBeforeGetContent = () => {

};
const { handlePrint } = useVueToPrint({
  content: () => printComponentRef.value,
  onAfterPrint: handleAfterPrint,
  onBeforeGetContent: handleOnBeforeGetContent,
  onBeforePrint: handleBeforePrint,
  removeAfterPrint: true
});      
onMounted(() => {
    updateStyle();
    const {id, resource} = route.params;
    if (id) {
        if (resource == "shipment") {
            loadShipmentPackages();
        } else if (resource == "receipt") {
            loadReceiptPackages();
        } else if (resource == 'package') {
            //const ids = atob(id).split(',');
            loadPackages(atob(id));
        }
    }
})

const onPageLoadData = () => {
    pageCounter.value = pageCounter.value + 1;
}

function onChangeType() {
    updateStyle();
}

function updateStyle() {
    const idValue = "printstyle";
    let path = `styles/${selectedType.value}.css`;
    const link = document.getElementById(idValue);
    if (link) {
        link.href = path;
    } else {
        const link = document.createElement('link');
        link.href = path;
        link.id = idValue;
        link.rel = "stylesheet";
        link.media = "screen,print";
        link.type = "text/css";
        document.head.appendChild(link);
    }
}
function loadPrintCounters() {
    let packageValues = packages.value;
    if (Array.isArray(packageValues) && packageValues.length > 0) {
        let ids = packageValues.map(pkg => pkg.id);
        PackageService.getPrintCounter(ids).then(data => {
            let map = {};
            if (Array.isArray(data) && data.length > 0) {
                data.forEach(item=>{
                    map[`${item.type}#${item.id}`] = item.counter;
                })
            }
            packageValues.forEach(pkg=>{
                pkg.printCounter = map[`${selectedType.value}#${pkg.id}`];
            })
            loaded.value = "loaded";
        })
    }
}
function loadShipmentPackages() {
    const {id, packageId} = route.params;
    ShipmentService.get(id).then((data) => {
        if (packageId != undefined && packageId != null && packageId != "" && Array.isArray(data?.packages)) {
            packages.value = data.packages.filter(item => item.id == packageId);
        } else {
            packages.value = data.packages || [];
        }             
        if (Array.isArray(data?.receipts)) {
            receipts.value = data.receipts.reduce((total, receipt)=> {
                total[receipt.receiptCode] = receipt;
                return total;
            },{});
            // for (let i = 0; i < data.receipts.length; i++) {
            //     receipts[data.receipts[i].receiptCode] = data.receipts[i];
            //     let receiptPkgs = data.receipts[i].packageEntities.filter(item => packageIds.includes(item.id));
            //     packages = packages.concat(receiptPkgs);
            // }
        } 
        // this.packages = packages;
        loadPrintCounters();                    
    });
}
function loadReceiptPackages() {
    const {id, packageId} = route.params;
    ReceiptService.get(id).then((data) => {
        receipts.value = {
            [data.receiptCode]: data,
        };
        if (packageId != undefined && packageId != null && packageId != "" && Array.isArray(data.packageDtos)) {
            packages.value = data.packageDtos.filter(item => item.id.toString() == packageId);
        } else {
            packages.value = data.packageDtos;
        }
        loadPrintCounters();                    
    });
}
function loadPackages(strIds) {
    PackageService.getPrintInfo(strIds).then((data) => {
        let newReceipts = {};
        let newPackages = [];
        data.forEach(receipt => {
            newReceipts[receipt.receiptCode] = receipt;
            newPackages = newPackages.concat(receipt.packageDtos);
        });
        receipts.value = newReceipts;
        packages.value = newPackages;
        loadPrintCounters();                    
    });
} 
</script>
<template>
    <div>
        <Toast class="no-print"/>
		<Toolbar class="col-12 mt-2 no-print">
			<template #start>
                <!-- <Button :label="$t('button.back')" icon="pi pi-arrow-left" @click="onBack"/>
                <i class="ml-2" /> -->
                <Dropdown v-model="selectedType" :options="types" optionLabel="name" optionValue="value" class="w-full md:w-15rem" @change="onChangeType"/>
                <i class="mr-2" />
				<Button v-if="pageCounter == packages.length && packages.length > 0"
                    :label="$t('button.print')" icon="pi pi-print" 
                    @click="handlePrint($event)"/>
				<i class="mr-2" />
                
			</template>
		</Toolbar>
        <div ref="printComponentRef">
            <div id="shippingForm" v-if = "selectedType==='shippingForm'">
                <ShippingForm                
                    v-for="pkgEntity in packages"
                    :key="pkgEntity.id"
                    :receipt="receipts[pkgEntity.receiptCode]"
                    :entity="pkgEntity"
                />
            </div>
            <div id="shippingFormEms" v-if = "selectedType==='shippingFormEms'">
                <ShippingFormEms               
                    v-for="pkgEntity in packages"
                    :key="pkgEntity.id"
                    :receipt="receipts[pkgEntity.receiptCode]"
                    :entity="pkgEntity"
                    @afterLoadData="onPageLoadData"
                />
            </div>
            <div id="shippingLabel" v-else-if="selectedType==='shippingLabel'">
                <ShippingLabel 
                    v-for="pkgEntity in packages" 
                    :key = "pkgEntity.id" 
                    :receipt="receipts[pkgEntity.receiptCode]" 
                    :entity="pkgEntity"
                />
            </div>
        </div>
    </div>
</template>
